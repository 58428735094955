import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

function createData(name, founder, location, discount, amazon) {
  return { name, founder, location, discount, amazon };
}

const rows = [
  createData(
    <a
      href="https://www.2curls1mission.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
      2 Curls 1 Mission
    </a>,
    "Dynasty Harris",
    "Massachusetts",
    "No",
    "No"
  ),
  createData(
    <a
      href="https://www.adwoabeauty.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
      Adwoa Beauty
    </a>,
    "Julian Addo",
    "Texas",
    "No",
    "No"
  ),
  createData(
    <a
      href="https://alikaynaturals.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
      Alikay Naturals
    </a>,
    "Rochelle Alikay Graham-Campbell",
    "Florida",
    "No",
    <a
      href="https://www.amazon.com/Alikay-Naturals-Caribbean-Coconut-Conditioner/dp/B01FYET4CQ?dchild=1&keywords=Alikay+Naturals&qid=1613328088&sr=8-1&linkCode=ll1&tag=natural032d-20&linkId=9b9b920c3fcce8921e2242582618e289&language=en_US&ref_=as_li_ss_tl"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer nofollow noopener"
    >
      Yes
    </a>
  ),
  createData(
    <a
      href="https://alodiahaircare.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
      Alodia Hair Care
    </a>,
    "Dr. Isfahan Chambers-Harris",
    "Maryland",
    "No",
    "No"
  ),
  createData(
    <a
      href="https://www.facebook.com/BeeMineOrganics/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
      Bee Mine Organics
    </a>,
    "Tracey Wild",
    "Unknown",
    "No",
    <a
      href="https://www.amazon.com/Bee-Mine-Avocado-Balanced-Conditioner/dp/B004YD44WO?&linkCode=ll1&tag=natural032d-20&linkId=68f6b7bfe3496f95bf883920b809efc1&language=en_US&ref_=as_li_ss_tl"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer nofollow noopener"
    >
      Yes
    </a>
  ),
  createData(
    <a
      href="http://www.bellebutters.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
      Belle Butters
    </a>,
    "Tasha Burton",
    "Missouri",
    "No",
    "No"
  ),
  createData(
    <a
      href="https://www.belnouvo.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
      Belnouvo Beauty
    </a>,
    "Arcman Durosier",
    "Texas",
    "No",
    "No"
  ),
  createData(
    <a
      href="https://bglh-marketplace.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
      BGLH
    </a>,
    "Leila Noelliste",
    "New York",
    "No",
    <a
      href="https://www.amazon.com/handmade/BGLH-Marketplace?&linkCode=ll1&tag=natural032d-20&linkId=4b9e2d5ce153395408a33b8878bf84ca&language=en_US&ref_=as_li_ss_tl"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer nofollow noopener"
    >
      Yes
    </a>
  ),
  createData(
    <a
      href="https://www.etsy.com/shop/BlueRozeBeauty"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
      Blue Roze Beauty
    </a>,
    "Brittany McKinney",
    "Virginia",
    "No",
    "No"
  ),
  createData(
    <a
      href="https://www.facebook.com/botanicalblissshop/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
      Botanical Bliss botanica
    </a>,
    "Unknown",
    "Georgia",
    "No",
    "No"
  ),
  createData(
    <a
      href="https://briogeohair.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
    Briogeo
    </a>,
    "Nancy Twine",
    "New York",
    "No",
    <a
      href="https://www.amazon.com/s?k=Briogeo&linkCode=ll2&tag=natural032d-20&linkId=9db444d6740c3db035b886ebb5d78278&language=en_US&ref_=as_li_ss_tl"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer nofollow noopener"
    >
      Yes
    </a>
  ),
  createData(
    <a
      href="https://www.browngirlorganics.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
    Brown Girl Organics
    </a>,
    "Dior Beye",
    "Florida",
    "No",
    "No"
  ),
  createData(
    <a
      href="https://www.camillerose.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
    Camille Rose Naturals 
    </a>,
    "Janell Stephens",
    "Louisiana",
    "No",
    <a
      href="https://www.amazon.com/stores/CamilleRose/Homepage/page/A4616501-B2E3-4B20-8CBE-F2B4D8A3F656?&linkCode=ll2&tag=natural032d-20&linkId=81bd4f695ea042ce3e7af49ae94fc463&language=en_US&ref_=as_li_ss_tl"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer nofollow noopener"
    >
      Yes
    </a>
  ),
  createData(
    <a
      href="https://chenatural.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
    Che Naturals
    </a>,
    "Che’",
    "Pennsylvania",
    "No",
    "No"
  ),
  createData(
    <a
      href="https://chocolatekinksandkurls.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
    Chocolate Kinks & Kurls 
    </a>,
    "Juanita Henderson",
    "Indiana",
    "No",
    "No"
  ),
  createData(
    <a
      href="https://www.chubbycurls.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
    Chubby Curls 
    </a>,
    "Manushkka Sainvil",
    "Colorado",
    "No",
    "No"
  ),
  createData(
    <a
      href="https://www.shopfemmenoire.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
    Curl Collection  
    </a>,
    "Nadja Renise",
    "Georgia",
    "No",
    "No"
  ),
  createData(
    <a
      href="https://curlsdynasty.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
    Curls Dynasty 
    </a>,
    "Nickie Nougaisse",
    "Florida",
    "No",
    <a
      href="https://www.amazon.com/Curls-Dynasty-Vanilla-Custard-Defining/dp/B07BGGCG7D?dchild=1&keywords=Curls+Dynasty&qid=1613681008&sr=8-1&linkCode=ll1&tag=natural032d-20&linkId=0ca5e09ede77ae4f2aaeaff21141c175&language=en_US&ref_=as_li_ss_tl"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer nofollow noopener"
    >
      Yes
    </a>
  ),
  createData(
    <a
      href="https://curljunkie.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
    Curl Junkie
    </a>,
    "Marsha Coulton",
    "New York",
    "No",
    "No"
  ),
  createData(
    <a
      href="https://www.curlmix.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
    Curlmix
    </a>,
    "Kimberly Lewis",
    "Florida",
    "No",
    <a
      href="https://www.amazon.com/Avocado-Moisturizer-Organic-Argan-Fragrance/dp/B07DLG9CV8?dchild=1&keywords=curlmix+hair+products&qid=1620054732&sr=8-1&linkCode=ll1&tag=natural032d-20&linkId=ee1973601bacc8f7a2e120fe44f3102b&language=en_US&ref_=as_li_ss_tl"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer nofollow noopener"
    >
      Yes
    </a>
  ),
  createData(
    <a
      href="https://curlorigin.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
    Curl Origin
    </a>,
    "Nadia Ayo",
    "New York",
    "No",
    "No"
  ),
  createData(
    <a
      href="https://curls.biz/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
    CURLS
    </a>,
    "Mahisha Dellinger",
    "California",
    "No",
    <a
      href="https://www.amazon.com/stores/Curls/Curls/page/12EE9C6D-58F1-41C6-92FE-22C4DC563320?&linkCode=ll2&tag=natural032d-20&linkId=9fb4742449ee202121d9a1322bf1bb26&language=en_US&ref_=as_li_ss_tl"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer nofollow noopener"
    >
      Yes
    </a>
  ),
  createData(
    <a
      href="https://www.curlsandpotions.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
    Curls & Potions
    </a>,
    "Michelle Nicole Fontaine-Jones",
    "n/a",
    "No",
    <a
      href="https://www.amazon.com/stores/CURLS+%26+POTIONS/page/3B87995D-28AD-4BD6-AA08-9E8E544A9A76?&linkCode=ll2&tag=natural032d-20&linkId=84c09c2cb5d65f4d23ab6af5ebbe29b6&language=en_US&ref_=as_li_ss_tl"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer nofollow noopener"
    >
      Yes
    </a>
  ),
  createData(
    <a
      href="https://www.curluxenaturals.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
    Curluxe Naturals
    </a>,
    "Anikitia Abram",
    "New York",
    "No",
    "No"
  ),
  createData(
    <a
      href="http://darcysbotanicals.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
    Darcy’s Botanicals
    </a>,
    "Lysandra Taylor",
    "Georgia",
    "No",
    "No"
  ),
  createData(
    <a
      href="https://www.dpbolvw.net/click-9160314-14354913"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
    Design Essentials
    </a>,
    "Cornell McBride Sr.",
    "Georgia",
    "No",
    <a
      href="https://www.amazon.com/designessentials?&linkCode=ll2&tag=natural032d-20&linkId=e61c65302fbe15e094d9a0eea829625d&language=en_US&ref_=as_li_ss_tl"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer nofollow noopener"
    >
      Yes
    </a>
  ),
  createData(
    <a
      href="https://divabycindy.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
    Diva By Cindy
    </a>,
    "Cindy Tawiah",
    "Marlyland",
    "No",
    <a
      href="https://www.amazon.com/Diva-Cindy-Super-Hair-Dress/dp/B0052ESNVC?dchild=1&keywords=DIVA+BY+CINDY&qid=1620928205&sr=8-2&linkCode=ll1&tag=natural032d-20&linkId=6d9d6fab4b112414ba64d23ed6d008c4&language=en_US&ref_=as_li_ss_tl"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer nofollow noopener"
    >
      Yes
    </a>
  ),
  createData(
    <a
      href="https://www.etaeshop.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
    E’Tae Natural Products
    </a>,
    "Lakisha Tompkins",
    "Pennsylvania",
    "No",
    <a
      href="https://www.amazon.com/Conditioner-Treatment-Buttershine-Natural-Products/dp/B01I4EAM1U?dchild=1&keywords=E%E2%80%99Tae+Natural+Products&qid=1620928366&sr=8-1&linkCode=ll1&tag=natural032d-20&linkId=ddd77e4118d3be681a024aeec42badaa&language=en_US&ref_=as_li_ss_tl"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer nofollow noopener"
    >
      Yes
    </a>
  ),
  createData(
    <a
      href="https://www.earthtonesnaturals.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
    Earthtones Naturals
    </a>,
    "Susan Walker",
    "Ontario",
    "No",
    "No"
  ),
  createData(
    <a
      href="https://edenbodyworks.com/"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer noopener"
    >
    Eden bodyworks
    </a>,
    "Jasmine Lawrence",
    "Washington",
    "No",
    <a
      href="https://www.amazon.com/stores/EDEN+BodyWorks/page/C3FBE307-3889-47B0-BBA0-B65355949AC2?&linkCode=ll2&tag=natural032d-20&linkId=9a37adaa082c8e6edc2cc31c035999ac&language=en_US&ref_=as_li_ss_tl"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer nofollow noopener"
    >
      Yes
    </a>
  )
];

export default function AcccessibleTable() {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} style={{marginBottom:"30px"}}>
      <Table className={classes.table} aria-label="caption table" style={{marginBottom:"15px"}}>
        <caption style={{paddingBottom:"0px", paddingTop:"15px", fontSize:"0.8rem"}}>Black Owned Hair Products - Updated 19 Jun 2021</caption>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "600", paddingLeft:"15px" }}>Company</TableCell>
            <TableCell align="left" style={{ fontWeight: "600" }}>
              Founder
            </TableCell>
            <TableCell align="left" style={{ fontWeight: "600" }}>
              Location
            </TableCell>
            <TableCell align="left" style={{ fontWeight: "600" }}>
              Discount
            </TableCell>
            <TableCell align="left" style={{ fontWeight: "600", paddingRight:"15px" }}>
              Amazon
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row" style={{paddingLeft:"15px"}}>
                {row.name}
              </TableCell>
              <TableCell align="left">{row.founder}</TableCell>
              <TableCell align="left">{row.location}</TableCell>
              <TableCell
                align="left"
                style={row.discount !== "No" ? { color: "orchid" } : null}
              >
                {row.discount}
              </TableCell>
              <TableCell
                align="left"
                style={row.amazon !== "No" ? { color: "orchid" } : null}
              >
                {row.amazon}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BasicTable from "../components/blackOwned/BlackOwnedTable";

import Button from '@material-ui/core/Button';

import FurtherReading from "../components/furtherReading"

function BlackOwnedProducts () {

return(
  <Layout>
    <SEO
      title="Black Owned Hair Products"
      description="List of over 100 black owned hair products. Black owned natural hair products/ beauty brands are something to be celebrated. Check out the list and show support."
    />
    <h1>Black Owned Hair Products</h1>
    <h2 style={{color:"Orchid"}}>List of over 100 black owned hair care companies</h2>

    <div style={{textAlign:"left"}}>
        {/* <a href="#list" style={{textDecoration:"none"}}> */}
    <Button variant="outlined" color="primary" disableElevation style={{marginBottom:"25px"}} href="#list">
        skip ahead to the list
    </Button>
    {/* </a> */}
    </div>

    <p>Black owned natural hair products or black owned beauty brands are something to be celebrated. They should be taken seriously so they stay with us for generations to come.</p>
    <p>Historically, we have always known how to take care of our own hair because our ancestors passed their knowledge down. Natural hair care is nothing new. Time, by being exposed to different beauty standards and various other reasons have caused some of us to miss out on that information. Instead relying on relaxing and straightening our hair to make it easier to manage. At the expense of healthy natural hair.</p>
    <p>So why should black ownership be celebrated? Our buying power is well into the billions, think of all the black people on the planet that use products to look after our hair. All of the products we use, for example - the car we drive and the clothes we wear are probably owned by people that don’t look like us. Which is one thing. When it comes to the hair products we use, the major afro black hair care companies are also not owned by people that look like us.</p>
    <p>We, who should have the knowledge to take care of our own hair, have people who don’t know what it’s like to be us, selling the products we should have ownership over.</p>
    <p>To be clear, I don’t want to take anything away from the established brands because they are popular for good reason. If their products didn’t work then people wouldn't buy them again and again. Also, it’s not like there is some non-black person running the company by themselves. They get feedback from us and there are black people employed by them.</p>
    <p>My issue is with ownership. We should be building wealth in our community from products that we use.</p>
    <p>Luckily times are changing for the better and more people are looking into buying black owned brands.</p>
    <p>Learn more in our discussion on - <Link to="/is-black-owned-racist/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
    is black-owned racist
            </Link>.</p>
    <p>I’m part of various natural hair groups on Facebook and I came across a discussion about natural hair products. I’ve been planning this article for a while and that discussion inspired me to get this published. Keyla from NaturallyChic made a really useful list. I borrowed it and added some extra information.</p>

    <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}><i>As an Amazon Associate I earn from qualifying purchases. Some of the links in this article may be affiliate links, which means I may receive a small commission at no additional cost to you if you decide to purchase something.</i></p>

    {/* <div id="list" style={{height:"20px"}}></div> */}
    <h2 id="list" style={{paddingTop:"15px"}}>List of Black Owned Hair Products</h2>
    <p style={{color:"Orchid"}}>I’ve got over 100 entries to get through so check back regularly to find updates.</p>
    {/* <p style={{fontWeight:"600", color:"Orchid"}}>Tips</p> */}
    <p>Scroll right on mobile to see the whole table</p>
    <p>Anything <i style={{color:"Orchid"}}>this colour</i> opens in a new window</p>
    <BasicTable />


    {/* <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/cswDUWU.jpg"
        alt="natural afro hair glossary jargon header"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@skmuse_?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Suad Kamardeen</a></p>
    </div> */}


    <h2 style={{color:"Orchid"}}>Get in Touch</h2>
    <p>Thanks for checking out the list. Please share it if you found it helpful. If you own a product company or know someone who does, <a
      href="https://www.facebook.com/naturalafrohaircare"
      style={{ textDecoration: "none", color: "orchid" }}
      target="_blank"
      rel="noreferrer nofollow noopener"
    >
    let us know via Facebook
    </a> and I'll add it to the list.</p>
    

    {/* <h2>Subtitle</h2> */}

    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>                  
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)
    }

export default BlackOwnedProducts
